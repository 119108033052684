<template>
    <div :class="['tooltip', `tooltip-${position}`]">
        <span class="iAmText">{{ toolTipText }}</span>
    </div>
    </template>
    
    <script>
    export default {
        name: 'ToolTip',
        props: {
            toolTipText: {
                type: String,
                required: true
            },
            position: {
                type: String,
                required: true
            }
        },
        computed: {
        }
    }
    </script>
    
    <style lang="scss" scoped>
    .tooltip {
        position: absolute;
        padding: 0.5rem;
        background: #575757;
        border-radius: 0.2rem;
        text-align: center;
        width: auto;
        min-width: 130px;
        color: white;
        span {
            width: 100%;
            text-align: center;
        }
        &::before {
            content: '';
            position: absolute;
            border-style: solid;
        }
    }
    
    .tooltip-top {
        top: -110%;
        left: 50%;
        transform: translateX(-50%);
        &::before {
            bottom: -0.3rem;
            left: 50%;
            transform: translateX(-50%);
            border-width: 0.4rem 0.4rem 0 0.4rem;
            border-color: #575757 transparent transparent transparent;
        }
    }
    
    .tooltip-bottom {
        bottom: -110%;
        left: 50%;
        transform: translateX(-50%);
        &::before {
            top: -0.3rem;
            left: 50%;
            transform: translateX(-50%);
            border-width: 0 0.4rem 0.4rem 0.4rem;
            border-color: transparent transparent #575757 transparent;
        }
    }
    
    .tooltip-left {
        left: -110%;
        top: 50%;
        transform: translateY(-50%);
        &::before {
            right: -0.4rem;
            top: 50%;
            transform: translateY(-50%);
            border-width: 0.4rem 0 0.4rem 0.4rem;
            border-color: transparent transparent transparent #575757;
        }
    }
    
    .tooltip-right {
        right: -110%;
        top: 50%;
        transform: translateY(-50%);
        &::before {
            left: -0.4rem;
            top: 50%;
            transform: translateY(-50%);
            border-width: 0.4rem 0.4rem 0.4rem 0;
            border-color: transparent #575757 transparent transparent;
        }
    }
    </style>
    